import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaFigma, FaWordpress, FaSketch } from "react-icons/fa";
import { DiPhotoshop } from "react-icons/di";
import { SiAdobexd, SiCanva, SiGoogleanalytics, SiInvision } from "react-icons/si";
import { BiSearchAlt2 } from "react-icons/bi";  // For SEO tools

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <FaFigma />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPhotoshop />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAdobexd />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiCanva />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaSketch />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiInvision />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaWordpress />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGoogleanalytics />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <BiSearchAlt2 /> {/* SEO Tools */}
      </Col>
    </Row>
  );
}

export default Toolstack;
