import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

// Importing images for the projects
import leaf from "../../Assets/Projects/Book store.png";
import emotion from "../../Assets/Projects/Menu.png";
import editor from "../../Assets/Projects/furniture m.png";
import chatify from "../../Assets/Projects/Profile Card UI - White.png";
import suicide from "../../Assets/Projects/donate.jpg";
import bitsOfCode from "../../Assets/Projects/calculator.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works</strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Book Store Landing Page"
              description="UI of book store"
            // ghLink="https://dribbble.com/aakritycpg"
            />
          </Col>


          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Mobile app UI Design of Furniture"
              description="Using Figma"
            // ghLink="https://dribbble.com/aakritycpg"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Visit Card"
              description="Created simple and attractive UI of Visit Card"
            // ghLink="https://dribbble.com/aakritycpg"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Charity and Donation Management System (Fundfusion)"
              description="Charity and donation project during the 6th semester"
              ghLink="https://dribbble.com/aakritycpg"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
