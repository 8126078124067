import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Namaste, I am <span className="purple">Akriti Chapagain </span>
            from <span className="purple"> Kusma, Parbat.</span>
            <br />
            a UI/UX designer and digital marketing specialist.
            My focus is on delivering creative solutions that connect with users and generate results.            <br />
            <br />
            I’ve earned a Bachelor's degree in Computer Application, which has fueled my passion for technology and design
            Apart from that:
            <br />

          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> I’m a big foodie.

            </li>
            <li className="about-activity">
              <ImPointRight /> I love gardening and have a special affection for plants.

            </li>
            <li className="about-activity">
              <ImPointRight /> I am a nature lover and enjoy trekking.

            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            Fun Fact: I’ve tried almost everything, but after all the twists and turns,
            I’ve found my spot. Funny how life comes full circle!
            {" "}
          </p>
          <footer className="blockquote-footer">Akriti</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
