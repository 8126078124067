import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaPenNib, FaSearchDollar, FaChartLine, FaUsers, FaPaintBrush } from "react-icons/fa";
import { MdOutlineDesignServices, MdContentPaste } from "react-icons/md";

function Skillset() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <MdOutlineDesignServices /> {/* UI/UX Design */}
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaPaintBrush /> {/* Visual Design */}
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaSearchDollar /> {/* SEO */}
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaChartLine /> {/* Digital Marketing */}
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaUsers /> {/* User Research */}
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <MdContentPaste /> {/* Content Creation */}
      </Col>
    </Row>
  );
}

export default Skillset;
